import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import firebase from 'firebase';
import {LogCaptureService} from './services/log-capture/log-capture.service';
import {environment} from '../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  language: string = this.translateService.currentLang;
  constructor(
    private platform: Platform,
    private logCaptureService: LogCaptureService,
    private translate: TranslateService, // add this
    private translateService: TranslateService,
  ) {
    this.initializeApp();
    this.languageChange();
  }



  languageChange() {

    const lang: string = localStorage.getItem('lang');
    console.log(lang);
    if(lang!=null)
    {
      this.translateService.use(lang);
    }
    else
    {
      this.translateService.use(this.language);
    }
    // add this
 }



  initializeApp() {    this.translate.setDefaultLang('fr'); // add this

    this.platform.ready().then(() => {

      firebase.initializeApp(environment.firebaseConfig);


      // Activation de la persistance de donnée
      firebase.firestore().enablePersistence();
    });
  }

  eventHandler(event) {
    this.logCaptureService.getLogEvent(event);
    if (event.type === 'fullscreenchange') {
      /* gérer un passage en plein écran */
    } else /* fullscreenerror */ {
      /* gérer une erreur de passage en plein écran */
    }
  }
}
